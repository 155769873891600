<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号">
                <a-input placeholder="请输入手机号" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户姓名">
                <a-input placeholder="请输入用户姓名" v-model="queryParam.realName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="选择小区">
                  <a-select
                    v-model="queryParam.communityId"
                    placeholder="请选择小区"
                    @change="changeCommunity"
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择楼宇">
                  <a-select
                    v-model="queryParam.buildingId"
                    placeholder="选择楼宇"
                    @change="changebuilding"
                  >
                    <a-select-option :key="0" :value="0">选择楼宇</a-select-option>
                    <a-select-option v-for="item in regionBuilding" :key="item.keyId" :value="item.id">
                      {{ item.buildingName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择单元">
                  <a-select
                    v-model="queryParam.unitId"
                    placeholder="选择单元"
                    @change="changeUnit"
                  >
                    <a-select-option :key="0" :value="0">选择单元</a-select-option>
                    <a-select-option v-for="item in regionUnit" :key="item.keyId" :value="item.id">
                      {{ item.unitName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!--a-col :md="5" :sm="24">
                <a-form-item label="选择房屋">
                  <a-select
                    style="width:170px"
                    v-model="queryParam.roomId"
                    placeholder="选择房屋"
                  >
                    <a-select-option :key="0" :value="0">选择房屋</a-select-option>
                    <a-select-option v-for="item in regionHouse" :key="item.keyId" :value="item.id">
                      {{ item.houseName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col-->
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="gotoMemberInfo(0)">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
        :showPagination="true"
      >
        <span slot="realNameInfo" slot-scope="text, record">
          <a-avatar size="small" v-if="record.headPic !== ''" :src="record.headPic"></a-avatar>
          <a-avatar size="small" v-else>u</a-avatar>
          <span style="margin-left: 5px;">{{ record.realName }}</span>
        </span>
        <span slot="gender" slot-scope="text">
          <a-tag color="#2db7f5" v-if="text === 1">男</a-tag>
          <a-tag color="#87d068" v-else-if="text === 2">女</a-tag>
          <a-tag color="cyan" v-else>未知</a-tag>
        </span>
        <span slot="urList" slot-scope="text, record">
          <div v-for="(action, k) in record.urList" :key="k">{{ action.address }}</div>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="gotoMemberInfo(record.keyId)">编辑</a>
        </span>
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { regionCommunity } from '@/api/community'
import { regionList } from '@/api/region'
import { regionBuilding } from '@/api/building'
import { regionUnit } from '@/api/unit'
import { regionHouse } from '@/api/house'
import { residentCondition } from '@/api/resident'
import { visitList } from '@/api/user'
const columns = [
  {
    title: '用户ID',
    dataIndex: 'keyId'
  },
  {
    title: '用户姓名',
    dataIndex: 'realName',
    scopedSlots: { customRender: 'realNameInfo' }
  },
  {
    title: '手机号',
    dataIndex: 'telephone'
  },
  {
    title: '登录账户号',
    dataIndex: 'account'
  },
  {
    title: '昵称',
    dataIndex: 'nickName'
  },
  {
    title: '性别',
    dataIndex: 'gender',
    scopedSlots: { customRender: 'gender' }
  },
  {
    title: '区域',
    dataIndex: 'urList',
    scopedSlots: { customRender: 'urList' }
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '150px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'MemberList',
  components: {
    STable,
    Ellipsis
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      specialVisible: false,
      tagVisible: false,
      form: this.$form.createForm(this, { name: 'addResidentFrom' }),
      tagform: this.$form.createForm(this, { name: 'tagFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        realName: '',
        telephone: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        areaCode: 0,
        roomId: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return visitList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      dataParams: {
        communityName: ''
      },
      regionList: [],
      defaultRegion: [],
      addDefaultRegion: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      regionBuilding: [],
      regionBuildingAdd: [],
      houseList: [],
      regionUnit: [],
      regionUnitAdd: [],
      special: {
        specialIdArr: []
      },
      specialList: [],
      regionHouse: [],
      regionHouseAdd: [],
      paginationSet: {
        total: 1000
      }
    }
  },
  filters: {
  },
  beforeRouteLeave (to, from, next) {
    // 打开详情页（或者下一个任意界面）之前，把筛选条件保存到localstorage，如果离开列表页并且打开的不是详情页则清除，也可以选择不清除
    console.log(to.name)
    if (to.name === 'GridVisitMemberInfo') {
      const condition = JSON.stringify(this.queryParam)
      localStorage.setItem('visiListCondition', condition)
    } else {
      localStorage.removeItem('visiListCondition')
    }
    next()
  },
  created () {
    this.regionListApi()
    if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
      this.queryParam.communityId = this.$route.query.communityId
    }

    if (this.$route.query.buildingId !== 0 && this.$route.query.buildingId !== undefined && this.$route.query.buildingId !== '') {
      this.queryParam.buildingId = this.$route.query.buildingId
    }

    if (this.$route.query.unitId !== 0 && this.$route.query.unitId !== undefined && this.$route.query.unitId !== '') {
      this.queryParam.unitId = this.$route.query.unitId
    }

    if (this.$route.query.roomId !== 0 && this.$route.query.roomId !== undefined && this.$route.query.roomId !== '') {
      this.queryParam.roomId = this.$route.query.roomId
    }

    const searchCondition = localStorage.getItem('visiListCondition')
    console.log('searchCondition', searchCondition)
    if (searchCondition !== null) {
      this.queryParam = JSON.parse(searchCondition)
    }

    if (this.queryParam.buildingId !== 0 || this.queryParam.communityId !== 0 || this.queryParam.unitId !== 0 || this.queryParam.roomId !== 0) {
      this.residentConditionApi(this.queryParam.communityId, this.queryParam.buildingId, this.queryParam.unitId, this.queryParam.roomId)
    }
  },
  watch: {
    $route (val) {
      if (val.name === 'GridVisitList') {
        this.regionListApi()
        if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
          this.queryParam.communityId = this.$route.query.communityId
        }

        if (this.$route.query.buildingId !== 0 && this.$route.query.buildingId !== undefined && this.$route.query.buildingId !== '') {
          this.queryParam.buildingId = this.$route.query.buildingId
        }

        if (this.$route.query.unitId !== 0 && this.$route.query.unitId !== undefined && this.$route.query.unitId !== '') {
          this.queryParam.unitId = this.$route.query.unitId
        }

        if (this.$route.query.roomId !== 0 && this.$route.query.roomId !== undefined && this.$route.query.roomId !== '') {
          this.queryParam.roomId = this.$route.query.roomId
        }

        const searchCondition = localStorage.getItem('visiListCondition')
        console.log('searchCondition', searchCondition)
        if (searchCondition !== null) {
          this.queryParam = JSON.parse(searchCondition)
        }

        if (this.queryParam.buildingId !== 0 || this.queryParam.communityId !== 0 || this.queryParam.unitId !== 0 || this.queryParam.roomId !== 0) {
          this.residentConditionApi(this.queryParam.communityId, this.queryParam.buildingId, this.queryParam.unitId, this.queryParam.roomId)
        } else {
          this.regionCommunity = []
          this.defaultRegion = []
          this.regionBuilding = []
          this.regionUnit = []
          this.regionHouse = []
        }
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    residentConditionApi (communityId, buildingId, unitId, roomId) {
      const _this = this
      residentCondition({ communityId: communityId, buildingId: buildingId, unitId: unitId, roomId: roomId }).then((res) => {
        if (res.errorCode === 0) {
          _this.regionCommunity = res.result.regionCommunity
          _this.defaultRegion = res.result.defaultRegion
          _this.queryParam.communityId = res.result.defaultCommunityId
          _this.queryParam.buildingId = res.result.defaultBuildingId
          _this.queryParam.unitId = res.result.defaultUnitId
          _this.regionBuilding = res.result.regionBuilding
          _this.regionUnit = res.result.regionUnit
          _this.regionHouse = res.result.regionHouse
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.queryParam = {
        id: '',
        houseName: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        roomId: 0,
        areaCode: 0,
        desc: ''
      }
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.areaCode = e[2] ? e[2] : 0
      this.queryParam.communityId = 0
      if (this.queryParam.areaCode > 0) {
        this.regionCommunityApi(this.queryParam.areaCode, 0)
      } else {
        this.regionCommunity = []
      }
      this.regionBuilding = []
      this.queryParam.buildingId = 0
      this.regionUnit = []
      this.queryParam.unitId = 0
      this.regionHouse = []
      this.queryParam.roomId = 0
    },
    changeCommunity (e) {
      this.queryParam.communityId = e
      this.regionBuildingApi(this.queryParam.communityId, 0)
      this.queryParam.buildingId = 0
      this.regionUnit = []
      this.queryParam.unitId = 0
      this.regionHouse = []
      this.queryParam.roomId = 0
    },
    changebuilding (e) {
      this.queryParam.buildingId = e
      this.regionUnitApi(this.queryParam.buildingId, 0)
      this.queryParam.unitId = 0
      this.regionHouse = []
      this.queryParam.roomId = 0
    },
    changeUnit (e) {
      this.queryParam.unitId = e
      // this.regionHouseApi(this.queryParam.unitId, 0)
      this.queryParam.roomId = 0
    },
    regionBuildingApi (communityId, type) {
      const _this = this
      regionBuilding({ communityId: communityId, isAll: 1 }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionBuilding = res.result.data
          } else {
            _this.regionBuildingAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionUnitApi (buildingId, type) {
      const _this = this
      regionUnit({ buildingId: buildingId, isAll: 1 }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionUnit = res.result.data
          } else {
            _this.regionUnitAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionHouseApi (unitId, type) {
      const _this = this
      regionHouse({ unitId: unitId, isAll: 1 }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionHouse = res.result.data
          } else {
            _this.regionHouseAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode, isAll: 1 }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gotoMemberInfo (memberId) {
      this.$router.push({ path: '/member/visit-member-info?memberId=' + memberId + '&type=2' })
    }
  }
}
</script>
